import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../components/seo';
import Layout from '../components/layout';
import ParallaxCache from '../utils/ParallaxCache';
import componentGenerator from '../utils/componentGenerator';
import DynamicTitle from '../components/dynamicTitle';
import GeneralHead from '../components/generalHead';

const LegalTemplate = ({
  data: {
    page: {
      metaTags,
      breadcrumb,
      components,
      slug,
      dynamicTitle,
    },
  },
  location,
}) => {
  const [period, setPeriod] = useState('monthly');
  const isDark =
    components &&
    ((components[1]?.background &&
      Boolean(components[1].background.toLowerCase() !== 'light')) ||
      components[1]?.__typename === 'DatoCmsHeroCarouselComponent');

  return (
    <ParallaxProvider>
      {typeof window !== 'undefined' && <ParallaxCache />}
      <Layout
        isDark={isDark}
        forceAnnouncement={components[0].announcement}
        location={location}
      >
        <DynamicTitle title={metaTags?.title} dynamicTitle={dynamicTitle} />
        {componentGenerator(
          components,
          slug,
          breadcrumb,
          isDark,
          period,
          setPeriod
        )}
      </Layout>
    </ParallaxProvider>
  );
};

export const Head = ({
  data: {
    page: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export const query = graphql`
  query($id: String) {
    page: datoCmsLegalPage(id: { eq: $id }) {
      ...datoCmsLegalPage
    }
  }
`;

export default LegalTemplate;
